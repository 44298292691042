.footer-container {
  position: relative;
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.footer-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: url("../../assets/img/left-footer-bg.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  /* filter: brightness(0.5); */
  object-fit: cover;
  height: 100%;
  width: 100vw;
  z-index: -10;
}

.footer-form {
  background-color: #1d1b1c;
  width: 80%;
  position: relative;
  padding: 5rem 5rem;
  margin-top: 3rem;
}

.footer-form__control,
.footer-form__control:focus {
  border: none;
  border-bottom: 1px solid #d4b03a !important;
  background-color: transparent;
  font-family: Inter, sans-serif;
  color: #fff;
  font-size: 0.85rem;
}

.footer-form__control::placeholder {
  color: #444444;
}

.form-control:focus-within {
  color: white;
}

.footer-form * textarea {
  border: 1px solid #d4b03a !important;
  position: relative;
}

.footer-leonardo-logo {
  width: 400px;
}
.footer-social-links > a {
  text-decoration: none;
  font-family: "Inter";
  font-weight: 500;
  font-size: 15px;
  line-height: 193.52%;
  color: #d4b03a;
}

.footer-send-btn,
.footer-send-btn:hover {
  font-family: Inter, sans-serif;
  background: rgba(212, 175, 55, 0.3) !important;
  padding: 0.5rem 2rem;
  backdrop-filter: blur(10px);
  border: 1px solid #d4b03a !important;
  border-radius: 4px;
}

.send-msg-btn,
.send-msg-btn:hover {
  font-family: Inter, sans-serif;
  padding: 1rem 2.5rem;
  position: absolute;
  top: -8%;
  left: 12%;
}

.right-footer-img {
  height: 100% !important;
  width: 100%;
  object-fit: cover;
}

.send-us-a-msg-top {
  /* content: ''; */
  height: 0;
  width: 150px;
  border-bottom: 50px solid #cccccc;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  border-top-left-radius: 10px;
  box-sizing: border-box;
  display: block;
}

/* small screens */
@media screen and (min-width: 320px) and (max-width: 575px) {
  .footer-form {
    width: 100%;
    padding: 1rem 2rem;
    margin-top: 4rem;
  }
}

/* tablets and iPad */
@media screen and (min-width: 576px) and (max-width: 768px) {
  .footer-form {
    width: 90%;
    margin-top: 5rem;
  }
}

/*  medium - large screens */
@media screen and (min-width: 789px) and (max-width: 1440px) {
  .footer-form {
    width: 80%;
    margin-top: 5rem;
  }
}
